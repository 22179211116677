.select .filter__control{
    height:2rem;
    min-height: 30px;
    overflow: hidden;
    border-color: #3b82f6;
    background: transparent;

    &:hover{
    border-color: #3b82f6;
    }
}

.select .filter__value-container--is-multi {
    display: flex;
    flex-wrap: nowrap;
    gap: 0.125rem;
    padding-bottom: 0;
    padding-top: 0;
    position: relative;
    height: 2rem;
}

.select.meeting-select{
    position: absolute;
    top: 0;
    .filter__multi-value{
        width: 420px;
    }

    .filter__value-container--is-multi{
        flex-wrap: wrap;
    }

    .filter__indicators{
        height:2rem;
    }

    .filter__indicator.filter__clear-indicator{
        display: none;
    }

    .filter__multi-value__remove{
        display: none;
    }
    .filter__multi-value{
        background-color: white;
        padding: 6px;
        display: none;
    }

    .filter__multi-value__label{
        padding: 0;
    }

    .filter__menu{
        z-index: 30;
    }

    .filter__menu-list{
        padding: 0;
    }

    .filter__option.filter__option--is-selected{
        background-color: rgba(0,0,0,0.02 );
        color:black
    }

    .filter__option{
        border-bottom: 1px solid rgba(0,0,0,0.1 );
    }

}

