@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=fallback');

@tailwind base;
@tailwind components;

// Additional styles
@import 'additional-styles/utility-patterns.scss';
@import 'additional-styles/range-slider.scss';
@import 'additional-styles/toggle-switch.scss';
@import 'additional-styles/theme.scss';
@import 'additional-styles/fancybox.scss';
@import 'additional-styles/react-select.scss';
@import 'additional-styles/sliding-pane';
@import 'additional-styles/react-datepicker.scss';

// react date-picker
@import 'react-datepicker/dist/react-datepicker.css';

//react quill
@import 'react-quill/dist/quill.snow.css';

//notifications
@import 'react-toastify/dist/ReactToastify.css';

@tailwind utilities;

@layer utilities {
    .border-spacing-0 {
        border-spacing: 0;
    }
}

body,
html {
    height: 100%;
}
