.fancybox__nav .carousel__button.is-prev {
    left: 0 !important;
}

.fancybox__nav .carousel__button.is-next {
    right: 0 !important;
}

.fancybox__nav .carousel__button.is-prev,
.fancybox__nav .carousel__button.is-next {
    height: 80%;
    width: 70px;
    border-radius: 0;
    transition: background-color 0.5s;
}

.fancybox__nav .carousel__button.is-next:hover,
.fancybox__nav .carousel__button.is-prev:hover {
    background-color: rgba(192, 192, 192, 0.1);
    transition: background-color 0.5s;
}
